.news-listings {

  .post + .post {
    border-top: 1px solid $silver;
  }

  h2.headline {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .date {
    display: block;
    margin-top: 0;
    font-family: $franklin-gothic;
    font-size: 16px;
    font-weight: 100;

    @mixin large-up {      
      font-size: 21px;
    }
  }

  .copy {
    display: block;
  }
}
