.news-detail {

  .news-image {
    display: block;
    max-width: 100%;
  }

  .headline {
    margin-bottom: 0;
  }

  .date {
    display: block;
    margin-top: 0;
    font-family: $franklin-gothic;
    font-size: 16px;
    font-weight: 100;

    @mixin large-up {      
      font-size: 21px;
    }
  }

  // .copy {

  // }

  // .link {

  // }

  // .attachments {

  // }

  // .back {

  // }
}
