.primary-content {

  .calendar-tabs {
    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }

    .calendar-view {
      display: none;

      @include large-up {      
        display: block;
      }
    }
  }

  .calendar-header {
    position: relative;

    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }

    .month-name {
      display: block;
      width: 100%;
      text-align: center;
    }

    .prev-month {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .next-month {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }


  table.calendar-grid,
  table.calendarGrid {
    width: 100%;
    border: 1px solid $silver;
    font-size: 12px;

    th {
      font-family: $franklin-gothic;
      font-size: 14px;
      color: $white;
      background: $resolution-blue;
      border-top: 1px solid $silver;
    }

    td {
      height: 127px;
      line-height: 14px;
      border: 1px solid $silver;
      padding: 8px;
    }


    .hasEvent {

     }

    .date {
      font-size: 14px;
    }

    .currentMonth {
      background: $white;
    }

    .otherMonth {
      background: #f1f1f1;
      color: $silver;

       a {
        color: $silver;
       }
    }

    .currentMonth .date {
      color: $merlin;
    }

    .day a {
      display: block;
      padding: 6px 0;
      font-size: 12px;
      line-height: 14px;
    }
  }
}


// .calendarList {
//   // display: block;
//   // width: 590px;
//   // padding: 0;
//   // margin: 0;
//   // font-size: 12px;
//   // td {
//   //   vertical-align: middle;
//   // }
// }

// .dayOfWeek {
//   // width: 58px;
//   // padding: 4px 0 5px;
//   // color: #aaa;
//   // text-align: center;
//   // border-right: 1px solid #fff;
// }

// .hasEvent .dayOfWeek {
//   // color: #636466;
//   // background: #eff0f2;
// }

// .dayOfMonth {
//   // width: 58px;
//   // padding: 4px 0 5px;
//   // font-weight: bold;
//   // color: #aaa;
//   // text-align: center;
//   // border-right: 1px solid #fff;
// }
// .hasEvent .dayOfMonth {
//   // color: #231f20;
//   // background: #dfe1e6;
// }

// .eventInfo {
//   // width: 582px;
//   // padding: 0;
//   // text-align: left;
//   span {
//     // display: block;
//     // padding: 0 20px 0 19px;
//   }
//   a {
//     // display: block;
//     // padding: 4px 0 5px;
//     // font-weight: bold;
//     // line-height: 18px;
//     // border-bottom: 1px solid #e1d6dd;
//     // &:last-child {
//     //   border-bottom: none;
//     // }
//   }
// }
// .hasEvent .eventInfo {
//   // background: #fbeff7;
// }

// .event {
//   // clear: both;
//   img {
//     // max-width: 75px;
//     // margin: 0;
//   }
// }

