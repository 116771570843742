.primary-content {

  .content-form {

    &::after {
      clear: both;
      content: "";
      display: table;
    }
    // @include clearfix;

    p {
      margin-top: 0;
    }

    fieldset {
      padding: 0;
      margin: 0;
      border: 0;
    }

    label {
      display: inline;
      width: 100%;
      overflow: hidden;
      font-family: $franklin-gothic;
      font-size: 18px;
      font-weight: 600;
      color: $resolution-blue;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    input[type='text'],
    input[type='password'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    textarea {
      display: block;
      width: 100%;
      padding: 6px 10px;
      font-family: $franklin-gothic;
      font-size: 24px;
      font-weight: 100;
      line-height: 28px;
      background: $spring-wood;
      border: 1px solid $silver;

      @include placeholder {
        // color: $jumbo;
      }
    }

    select {
       display: block;
      width: 100%;
      padding: 4px 10px 5px;
      font-family: $franklin-gothic;
      font-size: 24px;
      font-weight: 100;
      line-height: 28px;
      background: $spring-wood;
      border: 1px solid $silver;

      @include placeholder {
        // color: $jumbo;
      }
    }

    input[type='file'] {
      display: block;
      width: 100%;
      clear: both;
    }

    .checkbox {
      overflow: hidden;

      input[type='checkbox'] {
        display: block;
        float: left;
        width: 21px;
        height: 21px;
        margin-right: 10px;
        margin-top: 7px;
      }

      label {
        float: left;
        width: calc(100% - 31px);
        margin-top: 2px;
        overflow: initial;
        // font-size: 16px;
        // font-weight: 400;
        // line-height: 21px;
        // color: $jumbo;
        text-overflow: initial;
        text-transform: none;
        white-space: initial;
      }
    }

    .radio {

      ul {
        padding-left: 0 !important;
        margin-bottom: 0;
        margin-left: 0 !important;
        list-style: none;

        li {
          padding-left: 0 !important;
          margin-bottom: 19px;
          margin-left: 0 !important;

          &::after {
            clear: both;
            content: "";
            display: table;
          }

          // @include clearfix;

          &::before {
            display: none !important;
          }

          &:last-child {
            margin-bottom: 0;
          }

          input {
            display: block;
            float: left;
            width: 21px;
            height: 21px;
            margin-right: 10px;
            margin-top: 7px;
          }

          label {
            float: left;
            width: calc(100% - 31px);
            margin-top: 2px;
            overflow: initial;
            text-overflow: initial;
            text-transform: none;
            white-space: initial;
          }
        }
      }

      .radio-list {
        padding-left: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 19px !important;
        margin-left: 0 !important;
        list-style: none;

        li {
          padding-left: 0 !important;
          margin-bottom: 0;
          margin-left: 0 !important;
        }

        label {
          font-weight: 100;
        }
      }

      span {
        display: block;
        // padding-top: 19px;
        // padding-bottom: 19px;

        // @include clearfix;
        &::after {
          clear: both;
          content: "";
          display: table;
        }

        input {
          display: block;
          float: left;
          width: 21px;
          height: 21px;
          margin-right: 10px;
        }

        label {
          float: left;
          width: calc(100% - 31px);
          margin-top: 2px;
          overflow: initial;
          // font-size: 16px;
          // font-weight: 400;
          // line-height: 21px;
          // color: $jumbo;
          text-overflow: initial;
          text-transform: none;
          white-space: initial;
        }
      }
    }

    .form-control {
      padding-bottom: 19px;

      @include small-up {
        float: left;
        padding-left: 42px;
      }

      @include medium-up {
        float: none;
        padding-left: 0;
      }

      @include large-up {
        float: left;
        padding-left: 42px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .labelonly {
      padding-bottom: 4px;
    }

    .rule {
      display: block;
      clear: both;
      float: left;
      background: rgba(0, 0, 0, 0.2);
      height: 1px;
      font-size: 1px;
      line-height: 1px;
      margin: 10px 0 19px;
    }

    .first {

      @include small-up {
        padding-left: 0;
        clear: left;
      }
    }

    .last {
      padding-right: 0;
    }

    .one {

      @include small-up {
        width: calc((100% / 12) * 1);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 1);
      }
    }

    .two {

      @include small-up {
        width: calc((100% / 12) * 2);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 2);
      }
    }

    .three {

      @include small-up {
        width: calc((100% / 12) * 3);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 3);
      }
    }

    .four {

      @include small-up {
        width: calc((100% / 12) * 4);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 4);
      }
    }

    .five {

      @include small-up {
        width: calc((100% / 12) * 5);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 5);
      }
    }

    .six {

      @include small-up {
        width: calc((100% / 12) * 6);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 6);
      }
    }

    .seven {

      @include small-up {
        width: calc((100% / 12) * 7);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 7);
      }
    }

    .eight {

      @include small-up {
        width: calc((100% / 12) * 8);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 8);
      }
    }

    .nine {

      @include small-up {
        width: calc((100% / 12) * 9);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 9);
      }
    }

    .ten {

      @include small-up {
        width: calc((100% / 12) * 10);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 10);
      }
    }

    .eleven {

      @include small-up {
        width: calc((100% / 12) * 11);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 11);
      }
    }

    .twelve {

      @include small-up {
        width: calc((100% / 12) * 12);
      }

      @include medium-up {
        width: 100%;
      }

      @include large-up {
        width: calc((100% / 12) * 12);
      }
    }
  }
}

.form-note{
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 18px;
  width: 66%;
  display: block;
  clear: both;
}

.g-recaptcha {
  margin-top: 50px;
  margin-bottom: 40px;
}


.error-msg {
  color: $error;
}