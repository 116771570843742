.primary-content {

  .btn {
    display: block;
    float: left;
    width: auto;
    min-width: 200px;
    padding: 0 20px;
    margin: 0;
    font-family: $franklin-gothic;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    background: transparent;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;

    + .btn {
      margin-left: 40px;
    }
  }

  a.btn {

  }

  .btn-primary {
    color: $white;
    background: $rosewood;
    border-color: $temptress;

    &:hover {
      text-decoration: none;
      background-color: $resolution-blue;
      border-color: $stratos;
    }
  }

  .btn-alternate {

    &:hover {
    }
  }
}
