.primary-content {
  font-family: $georgia;
  font-size: 16px;
  line-height: 24px;
  color: $merlin;

  @include large-up {
    font-size: 21px;
    line-height: 32px;
  }

  h1 {
    position: relative;
    display: block;
    padding: 0;
    margin: 0 0 38px;
    font-family: $franklin-gothic-cond;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    color: $merlin;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($black, 0.2);

    @include large-up {
      font-size: 56px;
    }

    span {
      position: relative;
      bottom: -5px;
      display: inline-block;
      padding: 0 10px 0 0;
      background: $white;

      @include large-up {
        padding-bottom: 3px;
        bottom: -1px;
      }
    }
  }

  h2 {
    font-family: $franklin-gothic-cond;
    font-size: 21px;
    font-weight: 700;
    line-height: 22px;
    color: $resolution-blue;
    border-bottom: none;

    @include large-up {
      font-size: 26px;
      line-height: 32px;
    }
  }

  h3 {
    font-family: $franklin-gothic-cond;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $resolution-blue;

    @include large-up {
      font-size: 21px;
      line-height: 32px;
    }
  }

  h4 {
    font-family: $franklin-gothic-cond;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $resolution-blue;

    @include large-up {
      font-size: 18px;
      line-height: 32px;
    }
  }

  h5 {
    font-family: $franklin-gothic-cond;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    color: $resolution-blue;

    @include large-up {
      font-size: 18px;
      line-height: 32px
    }
  }

  a {
    font-family: $franklin-gothic;
    font-weight: 600;
    color: $rosewood;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    display: block;
    padding: 0 0 0 15px;
    margin: 0;
    font-family: $franklin-gothic;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    color: $resolution-blue;
    border-left: 5px solid $silver;

    @include large-up {
      font-size: 21px;
      line-height: 32px;
      margin-left: 37px;
      padding-left: 38px;
    }
  }
}






























// .subpage {

//   .primary-content {
//     // font-size: 16px;
//     // line-height: 28px;
//     // color: $jumbo;

//     // @include respond-to($venti) {
//       // font-size: 21px;
//       // line-height: 36px;
//     // }

//     h1 {
//       // margin-top: 0;
//       // margin-bottom: 3px;
//       // font-family: $riona-sans-light;
//       // font-size: 24px;
//       // font-weight: 400;
//       // line-height: 28px;
//       // color: $bondi-blue;

//       // @include respond-to($venti) {
//         // margin-bottom: 23px;
//         // font-size: 36px;
//         // line-height: 36px;
//       // }

//       .subhead {
//         // display: block;
//         // font-family: $riona-sans-light;
//         // font-size: 18px;
//         // font-weight: 400;
//         // line-height: 28px;
//         // color: $cloud-burst;
//         // text-transform: uppercase;

//         // @include respond-to($venti) {
//           // font-size: 27px;
//           // line-height: 36px;
//         // }

//         &:first-child {
//           // margin-top: 0;
//         }
//       }
//     }

//     h2 {
//       // margin-top: 41px;
//       // margin-bottom: 20px;
//       // font-family: $riona-sans-medium;
//       // font-size: 21px;
//       // font-weight: 400;
//       // line-height: 24px;
//       // color: $cloud-burst;
//       // text-transform: uppercase;

//       // @include respond-to($venti) {
//       //   font-size: 27px;
//       //   line-height: 36px;
//       // }

//       &:first-child {
//         // margin-top: 0;
//       }
//     }

//     h3 {
//       // margin-top: 39px;
//       // margin-bottom: 16px;
//       // font-family: $riona-sans-bold;
//       // font-size: 18px;
//       // font-weight: 400;
//       // line-height: 24px;
//       // color: $cloud-burst;

//       // @include respond-to($venti) {
//       //   font-size: 21px;
//       //   line-height: 36px;
//       // }

//       &:first-child {
//         // margin-top: 0;
//       }
//     }

//     h4 {
//       // margin-top: 39px;
//       // margin-bottom: 16px;
//       // font-family: $riona-sans-bold;
//       // font-size: 18px;
//       // font-weight: 400;
//       // line-height: 24px;
//       // color: $jumbo;

//       // @include respond-to($venti) {
//       //   font-size: 21px;
//       //   line-height: 36px;
//       // }
//     }

//     h5 {
//       // margin-top: 37px;
//       // margin-bottom: 0;
//       // font-family: $riona-sans-bold;
//       // font-size: 14px;
//       // font-weight: 400;
//       // line-height: 24px;
//       // color: $cloud-burst;
//       // text-transform: uppercase;

//       // @include respond-to($venti) {
//       //   font-size: 16px;
//       //   line-height: 36px;
//       // }
//     }

//     a {
//       // color: $bondi-blue;
//       // text-decoration: none;

//       &:hover {
//         // color: $cloud-burst;
//       }
//     }

//     blockquote {
//       // position: relative;
//       // display: block;
//       // padding: 30px 0 33px;
//       // margin: 31px 0;
//       // // font-family: $riona-sans-extralight-italic;
//       // font-family: $riona-sans-medium; // updated per client request.
//       // font-size: 18px;
//       // font-style: normal;
//       // font-weight: 400;
//       // line-height: 24px;
//       // color: $cloud-burst;
//       // border-top: 1px solid $bonjour;
//       // border-bottom: 1px solid $bonjour;

//       // @include respond-to($venti) {
//         // padding-left: 173px;
//         // font-size: 24px;
//         // line-height: 36px;
//       // }

//       &::before {
//         // display: none;

//         // @include respond-to($venti) {
//         //   position: absolute;
//         //   top: calc(50% - 31px);
//         //   left: 43px;
//         //   display: block;
//         //   width: 85px;
//         //   height: 62px;
//         //   background-image: url('../images/quote.svg');
//         //   background-position: 0 0;
//         //   background-repeat: no-repeat;
//         //   background-size: 100%;
//         //   content: '';
//         //   opacity: 0.24;
//         // }
//       }

//       p {
//         // text-indent: -10px;

//         &::before {
//           // position: relative;
//           // display: inline;
//           // content: '“';
//         }

//         &::after {
//           // position: relative;
//           // display: inline;
//           // content: '”';
//         }
//       }

//       b,
//       strong {
//         // font-family: $riona-sans-medium;
//         // font-weight: 400;
//       }
//     }

//     p.note {
//       // font-size: 14px;
//       // line-height: 22px;
//     }

//     hr {
//       // display: block;
//       // height: 1px;
//       // clear: both;
//       // background: $cloud-burst;
//       // border: 0;
//     }

//     p {
//       // margin-top: 18px;
//       // margin-bottom: 18px;
//     }

//     strong,
//     em,
//     b,
//     i {
//       // font-weight: 400;
//     }

//     strong {
//       // font-family: $riona-sans-medium;
//     }

//     b {
//       // font-family: $riona-sans-bold;
//     }

//     i {
//       // font-family: $riona-sans-italic;
//     }

//     em {
//       // font-family: $riona-sans-italic;
//     }

//     ul,
//     ol {
//       // margin-top: 0;
//       // margin-bottom: 0;
//     }
//   }
// }
