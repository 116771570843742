.primary-content {

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .img-right,
  .img-left {
    margin: 22px auto;


    @include large-up {      
      margin-top: 0;
    }

    img {
      display: block;
      margin: 0 auto;
    }

    .caption {
      display: block;
      padding: 9px 0;
      margin-top: 4px;
      font-family: $franklin-gothic;
      font-size: 13px;
      font-style: italic;
      font-weight: 500;
      line-height: 20px;
      border-bottom: 1px solid $silver;
    }
  }

  .img-right {

    @include large-up {      
      float: right;
      margin-right: 0;
      margin-left: 44px;
    }
  }

  .img-left {

    @include large-up {      
      float: left;
      margin-right: 44px;
      margin-left: 0;
    }
  }
}
