.main-content {

  @extend .card;

  background: $white;

  // @include clearfix;
  &::after {
    clear: both;
    content: "";
    display: table;
  }

  @include medium-up {
    padding: 38px 40px;
  }
}
