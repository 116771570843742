.primary-content {

  .columns {

    @include medium-up {
      padding-bottom: 40px;
    }
  }

  .two-column {

    .column {

      @include medium-up {
        float: left;
        width: calc((100% - 40px) / 2);
        margin-right: 40px;
      }

      + .column {

        @include medium-up {
          margin-top: 0;
          margin-right: 0;
        }
      }
    }
  }
}
