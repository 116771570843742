.primary-content {

  .accordion-container {
    // margin-bottom: 13px;
  }

  .accordion {
    display: block;

    + .accordion {
      margin-top: 20px;
    }

    .accordion-heading {
      position: relative;
      display: block;
      padding: 6px 20px;
      font-size: 21px;
      line-height: 36px;
      cursor: pointer;
      color: $resolution-blue;
      background: $mercury;

      // @include user-select(none);
      user-select: none;

      &::after {
        position: absolute;
        top: 5px;
        right: 20px;
        display: block;
        font-family: $font-awesome;
        font-size: 36px;
        content: '\f107';
      }

      h2,
      h3,
      h4 {
        display: block;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 21px;
        line-height: 36px;
        color: inherit;
      }

      &:hover {
        color: $white;
        background: $rosewood;

        h2,
        h3,
        h4 {
          color: inherit;
        }
      }
    }

    &.active {

      .accordion-heading {
        color: $white;
        background: $resolution-blue;

        &::after {
          transform: rotate(-180deg);
        }

        h2,
        h3,
        h4 {
          color: inherit;
        }
      }

      .accordion-content {
        display: block;
      }
    }
  }

  .accordion-content {
    display: none;
    padding: 36px 40px;
    font-family: $franklin-gothic;
    font-size: 18px;
    font-weight: 100;
    line-height: 36px;
    color: $scorpion;
    background: $wild-sand;

    > * {

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
