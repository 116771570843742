.blog-categories {
  width: calc((100% - 40px) * .25);
  margin-right: 40px;
  float: left;

  h4 {
    margin: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 20px 0;
  }

  li {
    font-size: 16px;
    line-height: 24px;
  }
}