.events-detail {

  .event-image {
    display: block;
    max-width: 100%;
  }

  .event-name {
    margin-bottom: 0;
  }

  .event-date {
    display: block;
    margin-top: 0;
    font-family: $franklin-gothic;
    font-size: 16px;
    font-weight: 100;

    @mixin large-up {      
      font-size: 21px;
    }
  }

  // .start-date {

  // }

  // .end-date {

  // }

  // .event-body {

  // }

  // .event-attachments {

  // }

  // .back {

  // }
}
