.primary-content {

  table:not(.calendar-grid) {
    width: 100%;

    th {
      padding: 6px 20px;
      font-family: $franklin-gothic;
      font-size: 18px;
      line-height: 28px;
      color: $white;
      text-align: left;
      background: $resolution-blue;
      border-bottom: 0;
      border-left: 3px solid $white;

      &:first-child {
        border-left: 0;
      }
    }

    td {
      padding: 6px 20px 7px;
      font-family: $franklin-gothic;
      font-size: 18px;
      font-weight: 100;
      line-height: 28px;
      color: $scorpion;
      text-align: left;
      background: $gallery;
      border-left: 3px solid $white;
      border-top: 3px solid $white;

      &:first-child {
        border-left: 0;
      }
    }

    tr:nth-of-type(even) {

      td {
        background: $wild-sand;
      }
    }

    .col-left {
      text-align: left;
    }

    .col-center {
      text-align: center;
    }

    .col-right {
      text-align: right;
    }
  }
}
