.blog-detail {

  h2 {
    margin-bottom: 0;
  }

  .pubdate {
    display: block;
    margin-top: 0;
    font-family: $franklin-gothic;
    font-size: 16px;
    font-weight: 100;

    @mixin large-up {      
      font-size: 21px;
    }
  }

  .categories {
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid $silver;
    border-bottom: 1px solid $silver;
    font-size: 16px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: inline-block;

  }

  li + li {
    margin-left: 20px;
  }
}