.primary-content {

  .tabs {
    padding: 0;
    margin: 0 0 1px;
    list-style: none;

    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }

    li {
      float: left;
      padding: 6px 20px;
      margin-right: 3px;
      font-family: $franklin-gothic;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: $resolution-blue;
      cursor: pointer;
      background: $mercury;

      // @include user-select(none);
      user-select: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $white;
        background: $rosewood;
      }

      &.active {
        color: $white;
        background: $resolution-blue;
      }
    }
  }

  .tab-content {
    display: none;
    padding: 36px 40px;
    font-family: $franklin-gothic;
    font-size: 18px;
    font-weight: 100;
    line-height: 36px;
    color: $scorpion;
    background: $wild-sand;
    border-top: 3px solid $white;

    &.active {
      display: block;
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
