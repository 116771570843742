.rss {
  border-top: 1px solid $silver;
  width: calc((100% - 40px) * .25);
  float: left;
  marign-right: 40px;
  padding-top: 20px;
  font-size: 16px;

  a img {
    display: inline-block;
    margin: 0 5px 0 0;
  }
}