.recent-posts {
  float: right;
  width: calc((100% - 40px) * .75);

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .post {
    display: block;
    padding: 0;
    margin: 0;
  }

  .post  + .post {
    border-top: 1px solid $silver;
  }

  .title h4{
    padding: 0;
    margin: .83em 0 0;
    font-family: $franklin-gothic-cond;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    color: $resolution-blue;

    @mixin large-up {      
      font-size: 28px;
      line-height: 32px;
    }

    a {
      color: inherit;
    }
  }

  .date-author {
    display: block;
    margin-top: 0;
    font-family: $franklin-gothic;
    font-size: 16px;
    font-weight: 100;

    @mixin large-up {      
      font-size: 21px;
    }
  }

  .teaser {
    display: block;
    margin: 1em 0;
    font-family: $georgia;
  }

  .readmore {
    margin: 1em 0;
  }
}
