.blog-search {
  padding-bottom: 1em;

  // @include clearfix;
  &::after {
    clear: both;
    content: "";
    display: table;
  }

  input[type='text'] {
    display: block;
    float: left;
    width: calc(100% - 148px);
    padding: 6px 8px;
    margin-right: 40px;
    font-weight: 400;
    border: 1px solid #cccccc;
    background: #faf8f5;
  }

  a {
    display: block;
    float: right;
    width: 108px;
    padding: 6px 20px;
    margin: 0;
    font-size: 18px;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    background: $rosewood;
    border: 1px solid $temptress;

    &:hover {
      background: $resolution-blue;
      border-color: $stratos;
      text-decoration: none;
    }
  }
}
