.member-list {
  display: block;
  padding: 0;
  margin: 0;
  font-family: $franklin-gothic;
  list-style: none;

  // @include clearfix;
  &::after {
    clear: both;
    content: "";
    display: table;
  }

  li {
    display: block;
    padding: 0;
    margin-bottom: 20px;

    @include small-up {      
      float: left;
      width: calc((100% - 40px) / 2);
      margin-left: 40px;
    }

    @include medium-up {      
      width: calc((100% - 80px) / 3);
    }

    @include large-up {      
      width: calc((100% - 120px) / 4);
    }

    &:nth-child(2n+1) {

      @include small-up {      
        margin-left: 0;
        clear: left;
      }

      @include medium-up {      
        margin-left: 40px;
        clear: none;
      }
    }

    &:nth-child(3n+1) {

      @include medium-up {      
        margin-left: 0;
        clear: left;
      }

      @include large-up {      
        margin-left: 40px;
        clear: none;
      }
    }

    &:nth-child(4n+1) {

      @include large-up {      
        margin-left: 0;
        clear: left;
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }

  .name {
    display: block;
    margin-top: 16px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: $resolution-blue;
  }

  .title {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .location {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
